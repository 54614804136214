@font-face {
  font-family: "conecta";
  font-style: normal;
  font-weight: normal;

  src: url(https://use.fontawesome.com/releases/v5.13.0/webfonts/fa-solid-900.eot);
  src: url(https://use.fontawesome.com/releases/v5.13.0/webfonts/fa-solid-900.eot?#iefix)
      format("embedded-opentype"),
    url(https://use.fontawesome.com/releases/v5.13.0/webfonts/fa-solid-900.woff2) format("woff2"),
    url(https://use.fontawesome.com/releases/v5.13.0/webfonts/fa-solid-900.woff) format("woff"),
    url(https://use.fontawesome.com/releases/v5.13.0/webfonts/fa-solid-900.ttf) format("truetype"),
    url(https://use.fontawesome.com/releases/v5.13.0/webfonts/fa-solid-900.svg#fontawesome)
      format("svg");
}

[class^="icon-"] {
  font-family: "conecta" !important;
  font-style: normal;
  font-weight: 900;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-casa:before {
  content: "\f015";
}
.icon-menos:before {
  content: "\f068";
}

.icon-comunidad:before {
  content: "\f1ad";
}
.icon-flecha-izq:before {
  content: "\f053";
}

.icon-usuario:before {
  content: "\f007";
}
.icon-add:before {
  content: "\2b";
}
user-notifications .icon-8xf {
  text-align: center;
  width: 70px;
  height: 66px;
  cursor: pointer;
}

ul.nav {
  margin-top: -8px;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
}

ul.nav > li.nav-item {
  padding: 0;
  width: "100%";
  margin: 0px;
  display: list-item;

  text-align: -webkit-match-parent;
  color: var(--left-bar-linkcolor);
}

ul.nav > li.nav-item :hover {
  color: var(--left-bar-linkcolor-active);
  background-color: var(--left-bar-dark-bgcolor);
}

ul.nav > li.nav-item a {
  border-right: 1px solid var(--left-bar-border-color);
  color: var(--left-bar-linkcolor);
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  transition: color linear 0.15s, background linear 0.15s;
  text-transform: uppercase;
}
ul.nav > li.nav-item a i {
  float: left;
  margin: 5px 10px 0 0;
  transition: all ease-in-out 0.1s;
  font-size: 1.2rem;
}

ul.nav > div > div > div > li.nav-item .nav {
  border-right: 1px solid var(--left-bar-border-color);
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  transition: color linear 0.15s, background linear 0.15s;
}
ul.nav div > div > div > li.nav-item nav i {
  float: left;
  margin: 5px 10px 0 0;
  transition: all ease-in-out 0.1s;
  font-size: 1rem;
}
ul.nav > li.nav-item nav {
  border-right: 1px solid var(--left-bar-border-color);
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-align: "center";
  transition: color linear 0.15s, background linear 0.15s;
}
ul.nav > li.nav-item nav i {
  float: left;
  margin: 5px 10px 0 0;
  transition: all ease-in-out 0.1s;
  font-size: 1rem;
}

ul.nav > li.nav-item nav.has-children {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}

ul.nav > div > div > div > li.nav-item {
  margin: -10px 0 0 0;
  display: list-item;
  text-align: -webkit-match-parent;
  color: var(--left-bar-linkcolor);
  min-width: 250px;
  padding-left: 30px;
}

ul.nav > div > div > div > li.nav-item :hover {
  color: var(--left-bar-linkcolor-active);
  background-color: var(--left-bar-dark-bgcolor);
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover {
  text-decoration: none;
}

/* pruebecita */
.alta365-planes {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.container.mt-5 {
  margin-top: 25px !important;
}

.alta365 .container.mt-4 {
  margin-top: 0px !important;
}

.estilo-seguro {
  background-color: #fffa99 !important; /* Add !important to override Bootstrap styles */
}

.estilo-asistente {
  background-color: #e9f6fb !important; /* Add !important to override Bootstrap styles */
}

.estilo-incidencia-borrada {
  color: #adadad !important;
}

.estilo-incidencia-borrada .btn {
  --bs-btn-color: #adadad;
  --bs-btn-border-color: #adadadv;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #adadad;
  --bs-btn-hover-border-color: #adadad;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #adadad;
  --bs-btn-active-border-color: #adadad;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #adadad;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #adadad !important;
  --bs-btn-border-color: #adadad !important;
  --bs-gradient: none;
}

.estilo-incidencia-borrada .css-bsqp9w-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 2.1875rem;
  color: #adadad;
}

.AppGooglo {
  height: 41vh;
  width: 84vw;
}

.map-container {
  height: 100%;
  width: 100%;
}

.icon-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 50px;
}

.actividad-icon-box {
  padding: 10px;
  border-radius: 7px;
  transition: all 0.3s ease;
  border: 2px solid #ffffff;
  cursor: pointer;
}

.actividad-icon-box:hover {
  transform: scale(1.1);
}

.actividad-selected {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #2a4c95;
  background-color: #f0f8ff;
}

/* Container for the entire icon + text */
.container-activ {
  display: flex;
  align-items: center;
  padding: 10px;
}

/* Icon or Image container to ensure uniform size */
.activ-icono {
  width: 40px; /* Adjust width to your preference */
  height: 40px; /* Adjust height to your preference */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px; /* Space between icon/image and text */
}

/* FontAwesome icons */
.activ-icono .fa-icon {
  font-size: 30px;
  color: #2a4c95; /* Your specified color */
}

/* Text styling */
.activ-texto {
  font-size: 16px;
  color: #000; /* Text color */
}

/* Ensure image fits properly in the container */
.activ-icono img {
  width: 100%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
}

.ocultainput {
  display: none;
}
