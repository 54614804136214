@media (max-width: 768px) {
  .container-formulario-qr img {
    width: 100%;
    height: auto;
  }
  .container-formulario-qr h4 {
    margin-left: 20px !important;
    margin-right: 0px !important;
  }
}
